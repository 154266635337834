<template>
	<div
		:class="mergedWrapperClass"
		:style="!player.playing && !player.currentTime ? { backgroundImage: `url(${thumbnailSrc})` } : {}"
	>
		<div v-if="player.muted && hasFirstInteract" class="flex flex-col items-center z-10">
			<button
				class="bg-[#0F0E0E] border-[9px] border-[#373737]/80 rounded-full w-fit p-5 hover:bg-[#CEF26F] group"
				@click="() => videoControls.toggle()"
				title="Clique para ouvir"
			>
				<VolumeOff class="text-white group-hover:text-black" />
			</button>

			<Button
				class="mt-6 px-4 w-fit"
				variant="black"
				@click="() => videoControls.toggle()"
				title="Clique para ouvir"
			>
				Clique para ouvir
			</Button>
		</div>

		<div
			class="absolute top-0 left-0 w-full cursor-pointer"
			:class="{
				'z-10': !player.muted,
			}"
		>
			<video
				:class="mergedVideoClass"
				:controls="false"
				ref="video"
				muted
				loop
				playsinline
				:autoplay="autoplay"
				:poster="thumbnailSrc"
				@click="() => videoControls.toggle()"
			>
				<source :src="videoSrc" type="video/mp4" />
			</video>
		</div>
	</div>
</template>

<script setup lang="ts">
import { VolumeOff } from 'lucide-vue-next'
import { twMerge } from 'tailwind-merge'

const props = defineProps<{
	videoSrc: string
	thumbnailSrc: string
	autoplay?: boolean
	videoClass?: string
}>()
const videoRef = useTemplateRef('video')
const player = reactive({
	muted: true,
	playing: false,
	currentTime: 0,
})
const hasFirstInteract = ref(false)
const attrs = useAttrs()

const mergedVideoClass = computed(() => {
	return twMerge(
		'w-full rounded-tr-[60px] rounded-bl-[60px] h-[310px] object-cover lg:h-[500px]',
		props.videoClass as string
	)
})
const mergedWrapperClass = computed(() => {
	return twMerge(
		'g-center bg-cover mx-6 lg:mx-0 h-[310px] lg:h-[500px] relative rounded-tr-[60px] rounded-bl-[60px] flex flex-col items-center justify-center',
		attrs.class as string
	)
})

const videoControls = {
	toggle() {
		if (!hasFirstInteract.value) {
			hasFirstInteract.value = true
		}

		if (player.muted) {
			this.unmute()
			this.start()
		} else {
			this.mute()
			this.pause()
		}
	},

	unmute() {
		if (!videoRef.value) {
			return
		}

		player.muted = videoRef.value.muted = false
	},

	mute() {
		if (!videoRef.value) {
			return
		}

		player.muted = videoRef.value.muted = true
	},

	pause() {
		if (!videoRef.value) {
			return
		}

		player.playing = false
		videoRef.value.pause()
	},

	start() {
		if (!videoRef.value) {
			return
		}

		player.playing = true
		videoRef.value.play()
	},

	pauseAll() {
		this.pause()
		this.mute()
	},
}

function updateCurrentTime(event: Event) {
	const target = event.target as HTMLVideoElement

	player.currentTime = target.currentTime
}

onUnmounted(() => {
	videoRef.value?.pause()

	videoRef.value?.removeEventListener('timeupdate', updateCurrentTime)
})

onMounted(() => {
	videoRef.value?.addEventListener('timeupdate', updateCurrentTime)
})

watch(
	() => player.playing,
	playing => {
		if (!playing) {
			videoRef.value?.pause()
		}
	}
)
</script>
